import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    de: {
      common: common_de
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18next}>
        <Route path="/" component={App} />
      </I18nextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
