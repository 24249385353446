import React from "react";
import { PayPalButton } from "react-paypal-button-v2";

const CLIENT = {
    sandbox:
        "AXa4jolXfKNIQ4lvxlRWRuBXFpaC9An8Ka6qKWiKZq2WoD323zMdyVBT_eJIMclBuIU9Coykvp7HqZJJ",
    production:
        "AXa4jolXfKNIQ4lvxlRWRuBXFpaC9An8Ka6qKWiKZq2WoD323zMdyVBT_eJIMclBuIU9Coykvp7HqZJJ"
};

const CLIENT_ID =
    process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;


export default class CustomPayPalButton extends React.Component {
    render() {
        return (
            <div>
                <PayPalButton
                    amount={this.props.price}
                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    onSuccess={(details, data) => {
                        alert("Transaction completed by " + details.payer.name.given_name);

                        // OPTIONAL: Call your server to save the transaction
                        return fetch("https://service.skytraxx.eu/api/paypal-transaction-complete", {
                            method: 'POST', // *GET, POST, PUT, DELETE, etc.
                            mode: 'cors',
                            headers: {
                              'Content-Type': 'application/json',
                              // 'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            body: JSON.stringify({
                                orderID: data.orderID,
                                token: this.props.token
                            })
                        });
                    }}

                    options={{
                        clientId: CLIENT_ID,
                        currency: "EUR",
                    }}

                />
            </div>
        );
    }
}