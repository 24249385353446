import './App.css';
import './paypal/paypal-imports.js'
import React, { useEffect } from 'react'
import CustomPayPalButton from './paypal/paypal-imports.js'
//import QRCode from './qrcode/qrcodecomponent.js'
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Container, Box, Button, TextField, Typography } from '@material-ui/core';
//import TextField from '@material-ui/core';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


export default function App(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  //const [showQR, setShowQr] = React.useState(false);
  const [showPaypal, setShowPaypal] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertEmpty, setShowAlertEmpty] = React.useState(false);
  const [showAlertNotValid, setShowAlertNotValid] = React.useState(false);
  const [price, setPrice] = React.useState(0);
  
  const [token, setToken] = React.useState("");
  const search = useLocation().search;

  useEffect(() => {
    const params = new URLSearchParams(search);
    setToken(params.get('t'));
  }, [search]);

  /*
    const handleShow = () => {
      setShowQr(true);
    }
  */

  const handleTokenChange = (e) => {
    setToken(e.target.value);
    setShowPaypal(false);
  }

  const analyzeToken = () => {
    setShowPaypal(false);
    setShowAlert(false);
    setShowAlertEmpty(false);

    const tokenRegex = new RegExp("^[0-9]{6}$");

    if (!token || token.length === 0) {
      setShowAlertEmpty(true);
      return;
    }

    if (tokenRegex.test(token)) {

      fetch("https://service.skytraxx.eu/api/isTokenValid", {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token
        })
      })
        .then(res => res.json())
        .then(json => {
          

          if (json.valid) {
            console.log(json);
            console.log(json.price);            
            setPrice(json.price);
            setShowPaypal(true);
            setShowAlert(false);
            setShowAlertEmpty(false);
          } else {
            setShowAlertNotValid(true);
          }
        });
    } else {
      setShowPaypal(false);
      setShowAlert(true);
    }
  }


  return (
    <div className="App">
      <header className="App-header">
        {t('main.title')}
      </header>

      <Container maxWidth="sm">
        <Box display="flex" flexDirection="row-reverse" m={2}>

          <Button size="small" variant="contained" onClick={() => i18n.changeLanguage('de')}>de</Button>
          <Button size="small" variant="contained" onClick={() => i18n.changeLanguage('en')}>en</Button>
        </Box>

        <Box display="flex" flexDirection="column" m={2}>
          <Box m={2}>
            <div className={classes.root}>
              {showAlertNotValid && <Alert severity="error">{t('token.notValid')}</Alert>}
              {showAlertEmpty && <Alert severity="error">{t('token.empty')}</Alert>}
              {showAlert && <Alert severity="error">{t('token.length')}</Alert>}
            </div>
          </Box>


          {/* <InputGroup.Text id="basic-addon1">Bitte token eingeben oder QR code scannen</InputGroup.Text> */}
          <Typography id="basic-addon1">{t('main.payment_text')}</Typography>

          <TextField id="outlined-basic" label="Token" variant="outlined" defaultValue={token} onChange={handleTokenChange.bind(this)} />

          <Box m={2}>
            <Button variant="contained" onClick={analyzeToken}>{t('main.pay')}</Button>
          </Box>

          {showPaypal ? <CustomPayPalButton token={token} price={price} /> : ""}

        </Box>
      </Container>
    </div>
  );
}
